<template>
  <!--Cover Photo -->
  <img class="card-img-top" :src="getCoverPhoto" alt="User cover photo"/>
  <div class="position-relative">
    <div class="profile-img-container d-flex align-items-center">
      <div v-if="showProfilePhoto" class="position-relative profile-img">
        <!-- Profile Photo -->
        <img :src="getPhoto" class="rounded img-fluid" alt="Card image"/>
        <div class="position-absolute top-0 end-0" v-if="isEditable">
          <input type="file" class="d-none" ref="profile" @change="UploadProfilePhoto">
          <button type="button" title="Edit Your Profile Photo" @click="$refs.profile.click()"
                  class="btn btn-icon btn-sm btn-icon rounded-0 btn-primary waves-effect waves-float waves-light">
            <i class="fas fa-pencil"></i>
          </button>
        </div>
      </div>
      <div class="profile-title ms-3">
        <h2 class="text-white">{{ name }}</h2>
        <p class="text-white mb-0">{{ designation }}</p>
        <p class="text-white mb-2">
          <span v-for="role in company_roles_data">
            {{ role.label }} ({{ role.serial_no }}),
          </span>
        </p>
      </div>
    </div>
  </div>

  <!-- Cover Photo Edit Button -->
  <div class="position-absolute top-0 end-0" v-if="isEditable">
    <input type="file" class="d-none" ref="cover" @change="UploadCoverPhoto">
    <button type="button" title="Edit Your Cover Photo" @click="$refs.cover.click()"
            class="btn btn-icon btn-sm btn-icon rounded-0 btn-primary waves-effect waves-float waves-light">
      <i class="fas fa-pencil"></i>
    </button>
  </div>
  <Cropper
      :img="cropper.img"
      :type="cropper.type"
      :name="cropper.name"
      :show="cropper.show"
      :aspectRatio="cropper.aspectRatio"
      @onImageCropped="imageCropped"
      @closeModal="closeModal"
  ></Cropper>
</template>

<script>
import ImageCropper from "@/components/molecule/contact/ImageCropper";

export default {
  name: 'ContactBanner',
  props: ['name', 'company_roles_data', 'designation', 'profilePhoto', 'coverPhoto', 'isEditable', 'showProfilePhoto'],
  emits: ['photoUploaded'],
  components: {
    Cropper: ImageCropper,
  },
  data: () => ({
    previewProfile: '',
    previewCover: '',

    unProcessedImage: '',

    defaultProfilePhoto: "/app-assets/images/profile-photo.png",
    defaultCoverPhoto: "/app-assets/images/cover-photo.png",

    cropper: {
      show: false,
      img: '/app-assets/images/skill.jpg',
      isCover: false,
      aspectRatio: 3.6,
      type: '',
      name: '',

    },
  }),
  computed: {
    getPhoto() {
      if (this.previewProfile) return this.previewProfile;
      if (this.profilePhoto) return this.profilePhoto;
      return this.defaultProfilePhoto;
    },

    getCoverPhoto() {
      if (this.previewCover) return this.previewCover;
      if (this.coverPhoto) return this.coverPhoto;
      return this.defaultCoverPhoto;
    },

    getName() {
      return this.name ?? 'N/A';
    },
  },
  methods: {
    async imageCropped(image) {
      let file
      this.cropper.show = false
      if (this.cropper.isCover) {
        this.previewCover = image
        file = await this.genFileFromDataURL(this.previewCover)
        this.$emit('photoUploaded', file, 'cover_photo')
      } else {
        this.previewProfile = image
        file = await this.genFileFromDataURL(this.previewProfile)
        this.$emit('photoUploaded', file, 'photo')
      }
    },
    async genFileFromDataURL(image) {
      const blob = await (await fetch(image)).blob();
      return new File([blob], this.unProcessedImage.name)
    },

    UploadCoverPhoto() {
      this.unProcessedImage = this.$refs.cover.files[0]
      this.cropper.aspectRatio = 3.6
      this.cropPhoto(true)
    },

    UploadProfilePhoto() {
      this.unProcessedImage = this.$refs.profile.files[0]
      this.cropper.aspectRatio = 1
      this.cropPhoto(false)
    },

    cropPhoto(isCover = true) {
      let image = this.unProcessedImage
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        let prevImage = e.target.result

        this.cropper.isCover = isCover
        this.cropper.img = prevImage
        this.cropper.type = this.unProcessedImage.type
        this.cropper.name = this.unProcessedImage.name
        this.cropper.show = true
      }
    },
    closeModal(){
      this.cropper.show = false
    }
  },
}
</script>
<style scoped>

#user-profile .profile-header {
  overflow: hidden; }

#user-profile .profile-header .profile-img-container {
  position: absolute;
  //bottom: -7rem;
  left: 2.14rem;
  z-index: 2; }

#user-profile .profile-header .profile-img-container .profile-img {
  height: 8.92rem;
  width: 8.92rem;
  border: 0.357rem solid #fff;
  background-color: #fff;
  border-radius: 0.428rem;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1); }

#user-profile .profile-header .profile-header-nav .navbar {
  padding: 0.8rem 1rem; }

#user-profile .profile-header .profile-header-nav .navbar .navbar-toggler {
  line-height: 0; }

#user-profile .profile-header .profile-header-nav .navbar .profile-tabs .nav-item i,
#user-profile .profile-header .profile-header-nav .navbar .profile-tabs .nav-item svg {
  margin-right: 0; }

#user-profile #profile-info .profile-star {
  color: #babfc7; }

#user-profile #profile-info .profile-star i.profile-favorite,
#user-profile #profile-info .profile-star svg.profile-favorite {
  fill: #ff9f43;
  stroke: #ff9f43; }

#user-profile #profile-info .profile-likes {
  fill: #ea5455;
  stroke: #ea5455; }

#user-profile #profile-info .profile-polls-info .progress {
  height: 0.42rem; }

#user-profile .profile-latest-img {
  transition: all 0.2s ease-in-out; }

#user-profile .profile-latest-img:hover {
  transform: translateY(-4px) scale(1.2);
  z-index: 10; }

#user-profile .profile-latest-img img {
  margin-top: 1.28rem; }

#user-profile .block-element .spinner-border {
  border-width: 0.14rem; }

@media (max-width: 991.98px) {
  #user-profile .profile-latest-img img {
    width: 100%;
  }


}

@media (min-width: 768px) {
  .profile-header-nav .profile-tabs {
    width: 100%;
    margin-left: 13.2rem; }
}

@media (max-width: 575.98px) {
  #user-profile .profile-header .profile-img-container .profile-img {
    height: 100px;
    width: 100px; }
  #user-profile .profile-header .profile-img-container .profile-title h2 {
    font-size: 1.5rem; }

}
.card-img-top{
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>
