<template>
    <div class="profile-header-nav">
      <!-- navbar -->
      <nav class="navbar navbar-expand-md navbar-light justify-content-end justify-content-md-between w-100">

        <button class="btn btn-icon navbar-toggler" type="button" data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                aria-expanded="false" aria-label="Toggle navigation">
            <i data-feather="align-justify" class="font-medium-5"></i>
        </button>

        <!-- collapse  -->
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <div class="profile-tabs d-flex justify-content-end flex-wrap mt-1 mt-md-0">
            <ul class="nav nav-pills mb-0">

              <li
                v-for="tab in tabs.filter(item => item.module === infoType || item.module === 'both')" :key="tab.id"
                class="nav-item" :class="tab.type && 'parent-list-visibility'">

                  <a
                    :href="`#${tab.id}`"
                    :class="[`nav-link fw-bold`,{ active: activeTabId === tab.id }]"
                    @click="onHandleClick(tab)"
                  >
                    <span class="d-md-block"> {{ tab.title }}</span>
                    <!-- <i data-feather="rss" class="d-block d-md-none"></i> -->
                  </a>

              </li>

 <!-- v-if="infoType === 'person'" -->
              <li v-if="tabs.length > 6" class="nav-item dropdown dropdown-contact parent-list-more-visibility">

                  <a
                      class="nav-link dropdown-toggle"
                      :class="activeTxt"
                      id="dropdown-contact"
                      href="#"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                    <span class="d-md-block"> More</span>
                  </a>

                  <div
                      class="dropdown-menu dropdown-menu-end profile_sub_menu"
                      style="transform:inherit;"
                      aria-labelledby="dropdown-user">
                        <a
                          v-for="tab in tabs.filter(item => item.type && item.module === infoType)" :key="tab.id"
                          :href="`#${tab.id}`" @click="onHandleClick(tab, true)"
                          :class="activeTabId === tab.id ? 'active' : 'sub_menu_nav'"
                          class="nav-link"
                        >
                          {{tab.title}}
                        </a>
                  </div>

              </li>


            </ul>
          </div>
        </div>
        <!--/ collapse  -->
      </nav>
      <!--/ navbar -->
    </div>
</template>

<script setup>
import { onMounted, reactive, ref } from "vue";
const activeTxt = ref('');
const props = defineProps(['tabs', 'activeTabId', 'infoType'])
const emit = defineEmits(['setActiveTab'])

const onHandleClick = (tab, isChildTab = false) => {
  emit('setActiveTab', tab);
  setActiveClass(isChildTab)
}

const setActiveClass = (isChildTab) => {

    setTimeout(() => {
      if (!isChildTab) {
        activeTxt.value = '';
      } else {
        activeTxt.value = 'active'
      }
    }, 50)
}

onMounted(() => {
  const isChildTab = props.tabs.some(item => item.id === props.activeTabId && item.type);
  setActiveClass(isChildTab);
})
</script>

<style scoped>
.sub_menu_nav{
    background: transparent !important;
    color: #5e5873 !important;
}

.sub_menu_nav:hover{
  color: #6f68b8 !important;
}
.parent-list-more-visibility{
  display: none;
}
.nav-pills .nav-link, .nav-tabs .nav-link {
    justify-content: start !important;
}
@media (max-width: 1632px){
  .parent-list-visibility{
    display: none !important;
  }

  .parent-list-more-visibility{
    display: inherit !important;
  }
}

@media only screen and (min-device-width: 800px) and (max-device-width: 990px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .nav-pills .nav-link {
      padding: 0.786rem 0.5rem !important;
  }
}

@media (max-width: 767.98px){
  html body .app-content {
      padding-top: 15% !important;
  }
}
</style>
