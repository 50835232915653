<template>
    <div class="card-header border-bottom pt-1 pb-1 header_txt">
        <h4 class="card-title">Address</h4>
    </div>
    <div class="card-body mt-2">
        <form class="form form-horizontal" @submit.prevent="$emit('storeContact', formData)">

            <div class="row">
                <div class="col-sm-6 offset-md-3 col-12">
                    <div class="row">

                    <div class="col-12">
                        <div class="mb-1 row">
                            <input type="text" id="full-address" class="form-control" v-model="formData.full_address"
                                        placeholder="Full Address" />
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="mb-1 row">
                            <input type="text" id="full-name" class="form-control" v-model="formData.country"
                                        placeholder="Country" />
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="mb-1 row">
                            <input type="text" id="full-name" class="form-control" v-model="formData.district"
                                        placeholder="District" />
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="mb-1 row">
                            <input type="text" id="full-name" class="form-control" v-model="formData.police_station"
                                        placeholder="Police station" />
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="mb-1 row">
                            <input type="text" id="full-name" class="form-control" v-model="formData.area"
                                        placeholder="Area" />
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="mb-1 row">
                            <input type="text" id="full-name" class="form-control" v-model="formData.post_code"
                                        placeholder="Post code" />
                        </div>
                    </div>

                    <div class="col-sm-6 offset-sm-3">
                        <button type="submit" class="btn btn-primary me-1">Submit</button>
                        <button type="reset" class="btn btn-outline-secondary">Reset</button>
                    </div>

                </div>
                </div>
            </div>

        </form>
    </div>
</template>

<script setup>

import {defineProps, onMounted, ref, watch} from "vue";
const props = defineProps({
  formData: {
    type: Object
  }
})

const formData = ref({
    full_address: '',
    country: '',
    district: '',
    police_station: '',
    area: '',
    post_code: '',
})

watch(() => props.formData, (newFormData) => {
  formDataSet();
});

const formDataSet = () => {
  formData.value = props.formData
}
onMounted(() => {
  formDataSet()
})
</script>

<style scoped></style>
